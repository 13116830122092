<template>
  <div>
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za mobil-workers-info-title-za">
        <div :class="mode ? 'text__day2' : 'text__night2'" class="title">{{ $t('message.report_card') }}</div>
        <div>
          <span v-if="recalculating" class="font-bold mr-5 text-danger">
            Пожалуйста подождите, идет процесс перерасчета месяца!
          </span>
          <el-button :size="'small'"
            :disabled="recalculating"
            @click="recalculateCalendarData()" 
            class="mi-icon mr-2" 
            type="success"
            icon="el-icon-refresh">
            {{ $t("message.recalculateMonth") }}
          </el-button>

          <el-date-picker
              :class="mode ? 'input__day' : 'input__night'"
              size="medium"
              v-model="select_date"
              type="month"
              :format="'MMMM yyyy'"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
      </div>
      <crm-new-calendar ref="calendarData" :staff_id="staff.id" :select_date="select_date"></crm-new-calendar>
    </div>

    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div :class="mode ? 'text__day2' : 'text__night2'" class="title">{{ $t("message.salary_rotation") }}</div>
        <div>
          <el-button
              class="mi-icon"
              type="success"
              size="small"
              icon="el-icon-refresh"
          >
            {{ $t("message.rotation") }}
          </el-button>
        </div>
      </div>

      <homePaymentChart :staff_id="staff.id"></homePaymentChart>

    </div>
    <staff-bonuses :staff_id="staff.id" v-can="'ProfileController@getBonuses'"></staff-bonuses>

    <staff-penalties :staff_id="staff.id" v-can="'ProfileController@getPenalties'"></staff-penalties>

    <profile-refa :staff_id="staff.id"></profile-refa>

    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div class="title">{{ $t("message.quality_control") }}</div>
      </div>
      <el-row :gutter="20" class="margin__none">
        <el-col :xs="24" :sm="8">
          <div class="refa__item">
            <el-card class="box-card bg-light-primary" :class="mode ? '' : 'text-primary__night'">
              <i
                  class="my-icon el-icon-pie-chart text-primary"
              ></i>
              <div class="homepayment__flex">
                <div class="text-center">
                  <span class="text-primary"> {{ $t("message.process") }} </span>
                </div>
                <div class="text-center mt-3">
                  <b class="text-primary">{{ $t("message.name_process") }}</b>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :xs="24" :sm="8">
          <div class="refa__item">
            <el-card class="box-card bg-light-success" :class="mode ? '' : 'text-success__night'">
              <i
                  class="
                                  my-icon
                                  el-icon-collection-tag
                                  text-success
                              "
              ></i>
              <div class="homepayment__flex">
                <div class="text-center">
                  <span class="text-success"> {{ $t("message.norms") }} </span>
                </div>
                <div class="text-center mt-3">
                  <b class="text-success">110</b>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :xs="24" :sm="8">
          <div class="refa__item">
            <el-card class="box-card bg-light-danger" :class="mode ? '' : 'text-danger__night'">
              <i
                  class="my-icon el-icon-data-line text-danger"
              ></i>
              <div class="homepayment__flex">
                <div class="text-center">
                  <span class="text-danger"> {{ $t("message.fact") }} </span>
                </div>
                <div class="text-center mt-3">
                  <b class="text-danger"> 104 </b>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <homeControlChart class="control__chart"></homeControlChart>
    </div>
    <!-- end  -->

    <profile-take-assent :staff_id="staff.id"></profile-take-assent>
    <!-- end card Изн -->

    <advance-salary :staff_id="staff.id"></advance-salary>
    <!-- end card Аванс -->

    <profile-complaint :staff_id="staff.id"></profile-complaint>
    <!-- end card Жалобы -->

    <additional-work :staff_id="staff.id"></additional-work>

  </div>
</template>

<script>
import HomeRefaChart from "./components/home-refa-chart.vue";
import staffBonuses from "./components/staff-bonuses.vue";
import staffPenalties from "./components/staff-penalties.vue";
import HomePaymentChart from "./components/home-payment-chart.vue";
import HomeControlChart from "./components/home-control-chart.vue";
import AdvanceSalary from "./components/advanceSalary.vue";
import ProfileComplaint from "./components/profileComplaint.vue";
import AdditionalWork from "./components/additionalWork.vue";
import ProfileTakeAssent from "./components/profileTakeAssent.vue";
import ProfileRefa from "./components/profile-refa.vue";
import moment from 'moment';
import {mapGetters} from 'vuex'
import CrmNewCalendar from "./staffCalendar/crm-new-calendar.vue";
export default {
  components: {
    CrmNewCalendar,
    HomeRefaChart,
    HomePaymentChart,
    HomeControlChart,
    AdvanceSalary,
    ProfileComplaint,
    ProfileTakeAssent,
    ProfileRefa,
    staffBonuses,
    staffPenalties,
    AdditionalWork
  },
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      recalculating: false,
      select_date: moment().format("YYYY-MM-DD"),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      mode: "MODE"
    })
  },
  methods: {
    formatMonthDate(value) {
      if (value) {
        return moment((value)).lang("ru").format('MMMM YYYY')
      }
    },
    async recalculateCalendarData() {
      if(this.$refs.calendarData && _.isFunction(this.$refs.calendarData.recalculateMonth)){
        this.recalculating = true;
        await this.$refs.calendarData.recalculateMonth();
        this.recalculating = false;
      }
    }
  }

};
</script>

<style lang="scss">
.small_button {
  font-size: 14px;
  color: #fff;
}

.crmhome__box {
  .box-card {
    .el-card__body {
      padding: 15px !important;

    }
  }
}

.cardtable__night .my-table-card thead tr th {
  background-color: #424662ad !important;

}
</style>
